import React from 'react'
import Layout from '../templates/layout'
import { Link } from 'gatsby'
import { ArrowLeft } from '../components/icons/index'
import { SEO, Heading, Text } from '../components/index'

const NotFoundPage = () => (
  <Layout id="top">
    <SEO title="404: Not found" />
    <div className="grid site-top">
      <div className="row">
        <div className="col col-12-xs col-0-xs-offset col-8-md col-2-md-offset">
          <Heading size="large">Not Found</Heading>
          <Text size="normal" margin="0 0 3rem 0">
            We couldn't find that page.
          </Text>
          <Text size="normal">
            <Link to="/">
              <ArrowLeft style={{ marginRight: '2.4rem' }} />
              Go back to homepage
            </Link>
          </Text>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
